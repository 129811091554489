import React, { useState, useEffect } from 'react';
import axios from 'axios';
import firebase from 'firebase/compat/app';
import Navbar from './Navbar';
import Advtop from './Advtop';
import ChampionInfo from './ChampionInfo';
import './LiveGameSearch.css'; // Import the CSS file
import LivePlayerInfo from './LivePlayerInfo'; // Import the LivePlayerInfo component
import ProLookup from './ProLookup';
import CookiesNotice from './CookieNotice';
import Footer from './Footer';

if (!firebase.apps.length) {
    firebase.initializeApp({
        apiKey: "AIzaSyAnX3pXtl3CYT6kr_uYK8dkxs7ZRYdc228",
        authDomain: "kjartan-app.firebaseapp.com",
        projectId: "kjartan-app",
        // ...
    });
}

const LiveGameSearch = () => {

    // Set the base URL for Axios
    axios.defaults.baseURL = 'http://lolfox.net:5000';

    const [liveGameData, setLiveGameData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [playerChampionInfo, setPlayerChampionInfo] = useState({});

    // Define the spell map
    const spellMap = {
        21: 'SummonerBarrier',
        1: 'SummonerBoost',
        14: 'SummonerDot',
        3: 'SummonerExhaust',
        4: 'SummonerFlash',
        6: 'SummonerHaste',
        7: 'SummonerHeal',
        13: 'SummonerClarity',
        30: 'SummonerTo the King!',
        31: 'SummonerPoro Toss',
        11: 'SummonerSmite',
        39: 'SummonerMark',
        32: 'SummonerMark',
        12: 'SummonerTeleport',
        54: 'SummonerPlaceholder',
        55: 'Placeholder and Attack-Smite',
    };


    // Function to update the champion information for a specific player
    const updatePlayerChampionInfo = (playerId, name, imageURL) => {
        setPlayerChampionInfo((prevState) => ({
            ...prevState,
            [playerId]: {
                name: name,
                imageURL: imageURL,
            },
        }));
    };

    useEffect(() => {
        // Get the player name from the URL
        const playerName = window.location.pathname.split('/').pop();

        // Check if the player data is already in the cache
        if (playerChampionInfo[playerName]) {
            setLoading(false);
            return;
        }

        // Make the API call to the Node.js server
        axios
            .get(`/api/livegame?playerName=${playerName}`)
            .then((response) => {
                //console.log(response.data)
                setLiveGameData(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching live game data:', error);
                setError('Error fetching live game data');
                setLoading(false);
            });
    }, []);

    if (loading) {
        return (
            <>
                <Navbar />

                <div className="loading-container text-light">
                    <img className="spinner" src={`${process.env.PUBLIC_URL}/assets/images/fox_logo.png`} alt="Loading Spinner" />
                    <p>Loading...</p>
                </div>

                <Advtop />
                <CookiesNotice />
                <Footer />
            </>
        )
    }

    if (error) {
        return (
            <>
                <Navbar />

                <div className="loading-container text-light">
                    <img className="spinn1er" src={`${process.env.PUBLIC_URL}/assets/images/fox_logo.png`} alt="Loading Spinner" />
                    <p>< div className=""> {error}</div></p>
                </div>

                <Advtop />
                <CookiesNotice />
                <Footer />
            </>

        )

    }

    if (!liveGameData) {
        return <div>No live game data found for the specified user.</div>;
    }

    if (liveGameData.gameQueueConfigId === 420) {

    }

    //Live Data

    //const bannedChampions = liveGameData.bannedChampions;
    const participants = liveGameData.participants;

    const blueTeamId = 100;
    const redTeamId = 200;
    const blueTeam = participants.filter((player) => player.teamId === blueTeamId);
    const redTeam = participants.filter((player) => player.teamId === redTeamId);


    // Display the live game data, including all 10 players
    return (
        <>
            <Navbar />
            <div className="wrapper wrapper-live">

                <div className="scoreboard">
                    <div className="live-team text-player">
                        <div className="text-blue blue-team">
                            <tr className="scoreboard-element">
                                <td className="top-team-element">
                                    <h3>Blue</h3>
                                </td>
                                <td className="scoreboard-rank-align">
                                    <h3 className="pink-text-scoreboard">Rank</h3>
                                </td>
                                <td className="tier-rank scoreboard-tier-rank">
                                    <h3 className="pink-text-scoreboard">Win Rate</h3>
                                </td>
                            </tr>
                        </div>

                        {blueTeam?.map((player) => (
                            <tr className="blue-player" key={player.summonerId}>
                                <td className="champion-image">
                                    {player.championId && (
                                        <ChampionInfo
                                            id={player.championId}
                                            onChampionName={(name) => updatePlayerChampionInfo(player.summonerId, name, null)}
                                            onChampionImage={(imageURL) => updatePlayerChampionInfo(player.summonerId, null, imageURL)}
                                        />
                                    )}
                                    {playerChampionInfo[player.summonerId]?.imageURL && (
                                        <div className="image-border">
                                            <img
                                                src={`https://ddragon.leagueoflegends.com/cdn/13.14.1/img/champion/${playerChampionInfo[player.summonerId].imageURL}`}
                                                alt={`Champion ${playerChampionInfo[player.summonerId].name}`}
                                            />
                                        </div>
                                    )}
                                </td>
                                <td className="summoner-spell">
                                    <img
                                        src={`https://ddragon.leagueoflegends.com/cdn/13.14.1/img/spell/${spellMap[player.spell1Id]}.png`}
                                        alt={`${spellMap[player.spell1Id]}`}
                                    />
                                    <img
                                        src={`https://ddragon.leagueoflegends.com/cdn/13.14.1/img/spell/${spellMap[player.spell2Id]}.png`}
                                        alt={`${spellMap[player.spell2Id]}`}
                                    />
                                </td>
                                <td className="summoner-name-scoreboard">
                                    <ProLookup summonerName={player.summonerName} />
                                </td>
                                <LivePlayerInfo summonerName={player.summonerName} />
                            </tr>
                        ))}

                    </div>
                    <div className="red-scoreboard live-team text-player">
                        <div className="text-bold red-team">
                            <tr className="scoreboard-element">
                                <td className="top-team-element">
                                    <h3>Red</h3>
                                </td>
                                <td className="scoreboard-rank-align">
                                    <h3 className="pink-text-scoreboard">Rank</h3>
                                </td>
                                <td className="tier-rank scoreboard-tier-rank">
                                    <h3 className="pink-text-scoreboard">Win Rate</h3>
                                </td>
                            </tr>
                        </div>
                        {redTeam?.map((player) => (
                            <tr className="red-player" key={player.summonerId}>
                                <td className="champion-image">
                                    {player.championId && (
                                        <ChampionInfo
                                            id={player.championId}
                                            onChampionName={(name) => updatePlayerChampionInfo(player.summonerId, name, null)}
                                            onChampionImage={(imageURL) => updatePlayerChampionInfo(player.summonerId, null, imageURL)}
                                        />
                                    )}
                                    {playerChampionInfo[player.summonerId]?.imageURL && (
                                        <div className="image-border">
                                            <img
                                                src={`https://ddragon.leagueoflegends.com/cdn/13.14.1/img/champion/${playerChampionInfo[player.summonerId].imageURL}`}
                                                alt={`Champion ${playerChampionInfo[player.summonerId].name}`}
                                            />
                                        </div>
                                    )}
                                </td>
                                <td className="summoner-spell">
                                    <img
                                        src={`https://ddragon.leagueoflegends.com/cdn/13.14.1/img/spell/${spellMap[player.spell1Id]}.png`}
                                        alt={`${spellMap[player.spell1Id]}`}
                                    />
                                    <img
                                        src={`https://ddragon.leagueoflegends.com/cdn/13.14.1/img/spell/${spellMap[player.spell2Id]}.png`}
                                        alt={`${spellMap[player.spell2Id]}`}
                                    />
                                </td>
                                <td className="summoner-name-scoreboard">
                                    <ProLookup summonerName={player.summonerName} />
                                </td>
                                <LivePlayerInfo summonerName={player.summonerName} />
                            </tr>
                        ))}
                    </div>
                    <div className="scoreboard-bottom">

                    </div>
                </div>
                <Advtop />
                <Footer />
            </div>
        </>
    );
};

export default LiveGameSearch;
