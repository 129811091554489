import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const AccountInfo = ({ name }) => {
    // Initialize Firestore
    const db = firebase.firestore();

    const [accounts, setAccounts] = useState([]);
    const [loading, setLoading] = useState(true);

    // Map tier values to numeric values
    const tierMap = {
        "UNRANKED": 0,
        "IRON": 1,
        "BRONZE": 2,
        "SILVER": 3,
        "GOLD": 4,
        "PLATINUM": 5,
        "DIAMOND": 6,
        "MASTER": 7,
        "GRANDMASTER": 8,
        "CHALLENGER": 9,
    };

    const rankMap = {
        "IV": 0,
        "III": 1,
        "II": 2,
        "I": 3,
    }

    useEffect(() => {
        // Function to fetch data from Firestore
        const fetchAccounts = async () => {
            try {
                setLoading(true);
                const querySnapshot = await db.collection("accounts")
                    .where("name", "==", name)
                    .get();

                const accountData = querySnapshot.docs.map(doc => doc.data());

                // Sort the accounts based on tier, rank, and leaguePoints
                accountData.sort((a, b) => {
                    const tierComparison = tierMap[b.tier] - tierMap[a.tier];
                    if (tierComparison !== 0) return tierComparison;

                    const rankComparison = rankMap[b.rank] - rankMap[a.rank];
                    if (rankComparison !== 0) return rankComparison;

                    return b.leaguePoints - a.leaguePoints;
                });

                setAccounts(accountData);
                setLoading(false);
                
            } catch (error) {
                console.error('Error fetching accounts:', error);
                setLoading(false);
            }
        };

        // Call the fetchAccounts function
        fetchAccounts();
    }, [db, name]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (accounts.length === 0) {
        return <div>No accounts found for the specified name.</div>;
    }

    // Function to calculate win rate
    const calculateWinRate = (wins, losses) => {
        return wins && losses ? ((wins / (wins + losses)) * 100).toFixed(0) : 'N/A';
    };

    return (
        <>
            {accounts.map(account => (
                <div key={account.username} className="accounts-container">
                    <div className="account-pfp">
                        <img className="summoner-icon-med" alt="Summoner Icon" src={`https://ddragon.leagueoflegends.com/cdn/13.14.1/img/profileicon/${account.profileIconId}.png`}></img>
                        <div className="flex-container">
                        <h1 className="text-light account-name">{account.username}</h1>
                        <a className="opgg-wrapper" target="_blank" href={`https://www.op.gg/summoners/na/${account.username}`}>
                            <img className="opgg-logo" src={`${process.env.PUBLIC_URL}/assets/images/opgglogo.svg`} alt="op.gg"></img>
                        </a>
                        </div>
                    </div>
                    <div className="stats-pfp">
                        <div>
                            <p>{`${account.tier} ${account.rank}`}</p>
                            <p>{account.leaguePoints} LP</p>
                            <p>{account.wins}W {account.losses}L</p>
                            <p>Win Rate {calculateWinRate(account.wins, account.losses)}%</p>
                        </div>
                    </div>
                    <div className="rank stats-rank">
                        <img title={`${account.tier} ${account.rank}`} src={`${process.env.PUBLIC_URL}/assets/images/${account.tier}_big.png`} alt={account.rank} className="stats-rank"></img>
                    </div>
                </div>
            ))}
        </>
    );
};

export default AccountInfo;