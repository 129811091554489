import { useEffect, useState } from 'react';

const ChampionInfo = ({ id, onChampionName, onChampionImage }) => {
  const [dataFetched, setDataFetched] = useState(false);

  useEffect(() => {
    // If the data has already been fetched for this player, return early
    if (dataFetched) return;

    // Define the URL of the champion data
    const championDataURL =
      'https://ddragon.leagueoflegends.com/cdn/13.14.1/data/en_US/champion.json';

    // Fetch the champion data from the URL
    fetch(championDataURL)
      .then((response) => response.json())
      .then((data) => {
        // Find the name of the associated champion using the "id" parameter
        const champion = Object.values(data.data).find(
          (champion) => parseInt(champion.key) === id
        );

        if (champion) {
          // Invoke the onChampionName callback with the champion name
          
          onChampionName(champion.name);

          // Fetch additional data for the champion using the "id" from the previous request
          const championDetailsURL = `https://ddragon.leagueoflegends.com/cdn/13.14.1/data/en_US/champion/${champion.id}.json`;

          // Fetch the detailed champion data
          fetch(championDetailsURL)
            .then((response) => response.json())
            .then((championData) => {
              // Extract the full image URL from the detailed champion data
              const fullImageURL = championData.data[champion.id].image.full;

              // Invoke the onChampionImage callback with the full image URL
              onChampionImage(fullImageURL);
            })
            .catch((error) => {
              console.error('Error fetching champion details:', error);
            });
        } else {
          // If the champion is not found, invoke the onChampionName and onChampionImage callbacks with error messages
          onChampionName('Champion not found');
          onChampionImage(null);
        }
      })
      .catch((error) => {
        console.error('Error fetching champion data:', error);
      })
      .finally(() => {
        // Mark data as fetched for this player to prevent further fetches
        setDataFetched(true);
      });
  }, [id, onChampionName, onChampionImage, dataFetched]);

  return null;
};

export default ChampionInfo;
