import React, { useState } from 'react';
import axios from 'axios';
import firebase from 'firebase/compat/app';

if (!firebase.apps.length) {
    firebase.initializeApp({
        apiKey: "AIzaSyAnX3pXtl3CYT6kr_uYK8dkxs7ZRYdc228",
        authDomain: "kjartan-app.firebaseapp.com",
        projectId: "kjartan-app",
        // ...
    });
}


const LeagueAccountAddition = () => {
    axios.defaults.baseURL = 'http://lolfox.net:5000';

    const [userNameIn, setUserNameIn] = useState('');
    const [summonerName, setSummonerName] = useState('');
    const [accountInfo, setAccountInfo] = useState(null);

    const handleSearch = async () => {
        try {
            const response = await axios.get(`/api/summoner?summonerName=${summonerName}`);
            setAccountInfo(response.data);
            console.log(response.data);

            const db = firebase.firestore();
            const docRef = db.collection('accounts').doc(summonerName.toLowerCase());

            const rankedData = response.data.rankedData.find((data) => data.queueType === "RANKED_SOLO_5x5");
            console.log(rankedData);

            if (rankedData) {
                const {
                    tier,
                    rank,
                    leaguePoints,
                    wins,
                    losses,
                } = rankedData;
                const {
                    profileIconId,
                    accountId,
                } = response.data.summonerData;
                const username = summonerName;
                const usernameId = summonerName.toLowerCase();
                const name = userNameIn;
                const nameId = userNameIn.toLowerCase();

                const accountData = {
                    tier,
                    rank,
                    leaguePoints,
                    wins,
                    losses,
                    profileIconId,
                    accountId,
                    username,
                    usernameId,
                    name,
                    nameId,
                };

                await axios.post('/api/updateUserData', {
                    summonerName,
                    userNameIn,
                    accountInfo: accountData,
                });
            } else {
                const tier = "UNRANKED";
                const rank = "";
                const leaguePoints = 0;
                const wins = 0;
                const losses = 0;

                const {
                    profileIconId,
                    accountId,
                } = response.data.summonerData;
                const username = summonerName;
                const usernameId = summonerName.toLowerCase();
                const name = userNameIn;
                const nameId = userNameIn.toLowerCase();

                const accountData = {
                    tier,
                    rank,
                    leaguePoints,
                    wins,
                    losses,
                    profileIconId,
                    accountId,
                    username,
                    usernameId,
                    name,
                    nameId,
                };

                await axios.post('/api/updateUserData', {
                    summonerName,
                    userNameIn,
                    accountInfo: accountData,
                });
            }
        } catch (error) {
            console.error('Error fetching account data:', error);
            setAccountInfo(null);
        }
    };


    return (
        <>
            <div>
                <input
                    type="text"
                    value={userNameIn}
                    onChange={(e) => setUserNameIn(e.target.value)}
                    placeholder="Enter Player Name"
                />
                <input
                    type="text"
                    value={summonerName}
                    onChange={(e) => setSummonerName(e.target.value)}
                    placeholder="Enter Summoner Name"
                />
                <button onClick={handleSearch}>Update</button>

                {accountInfo && (
                    <div className="text-light">
                        <h2>Ranked Data:</h2>
                        <div>
                            <strong>Tier:</strong> {accountInfo.rankedData[0]?.tier}<br />
                            <strong>Rank:</strong> {accountInfo.rankedData[0]?.rank}<br />
                            <strong>League Points:</strong> {accountInfo.rankedData[0]?.leaguePoints}<br />
                            <strong>Wins:</strong> {accountInfo.rankedData[0]?.wins}<br />
                            <strong>Losses:</strong> {accountInfo.rankedData[0]?.losses}<br />
                            <strong>profileIconId:</strong> {accountInfo.summonerData.profileIconId}<br />
                            <strong>accountId:</strong> {accountInfo.summonerData.accountId}<br />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default LeagueAccountAddition;