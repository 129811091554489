import React, { useState, useEffect } from 'react';

const TextFileDisplay = () => {
  const [fileContent, setFileContent] = useState('');

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/riot.txt`)
      .then(response => response.text())
      .then(text => setFileContent(text))
      .catch(error => console.error('Error fetching text file:', error));
  }, []);

  return (
    <div>
      <h2>riot.txt</h2>
      <pre>{fileContent}</pre>
    </div>
  );
};

export default TextFileDisplay;