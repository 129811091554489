import React from 'react';
import './Chatbar.css';
import AddDataForm from './AddDataForm';


function Leftbar() {
    return (
        <div className="chatbar-container">
            <div className="spacing"></div>
            <div className="text-light-custom admin-panel-text">Admin Panel</div>
            <div className="seperator"></div>

            <div className="spacing"></div>
            <AddDataForm />
        </div>
    )
}

export default Leftbar