import React from 'react';
import './LadderPage.css';
import Navbar from './Navbar';
import Advtop from './Advtop';
import LadderList from './LadderList';
import Footer from './Footer';

function LadderPage() {

    return (

        <>
            <Navbar />
            <div className="wrapper">
                <div className="wrapper-ladder-100">
                    <div className="Ladder-container-100">
                        <LadderList />
                    </div>
                </div>
                <Advtop />
                <Footer />
            </div>
        </>

    )
}

export default LadderPage