import React from 'react';
import './Footer.css';
import CookiesNotice from './CookieNotice';

const Footer = () => {
    const currentYear = new Date().getFullYear();
    const companyName = 'lolfox.net'; // Replace with your actual company name

    return ( 
        <div className="footer-container text-light-footer">
            <p>&copy; {currentYear} {companyName}. All rights reserved.</p>
            <div className="footer-inner">
                <p>Logo designed and illustrated by</p>
                <img
                        src={`${process.env.PUBLIC_URL}/assets/images/twitter_small.png`}
                        alt="Twitter"
                        className="hint--top footer-socials"
                    ></img>
                <a href="https://twitter.com/Im_Raechu" className="raechu-text">
                    @Im_Raechu
                </a>
            </div>
            <CookiesNotice />
        </div>
    )
}

export default Footer