import './TopPageItems.css';
import { Link } from 'react-router-dom';

function TopPageItems() {
    return (
        <div className="top-system-wrapper">
            <div className="top-system-container pink-text">
                <Link to="/search">
                    <img className="top-system-img camera-top" src={`${process.env.PUBLIC_URL}/assets/images/camera_logo.avif`} alt="live logo" />
                    <p>Search</p>
                </Link>
            </div>
            <div className="top-system-container pink-text">
                <a href="https://discord.gg/rnNsJjwUAB">
                    <img className="top-system-img discord-top" src={`${process.env.PUBLIC_URL}/assets/images/discord_logo.avif`} alt="discord logo" />
                    <p>Discord</p>
                </a>
            </div>
            <div className="top-system-container pink-text">
                <Link to="/ladder"> 
                    <img className="top-system-img leagues-top" src={`${process.env.PUBLIC_URL}/assets/images/leagues_logo.avif`} alt="ladder logo" />
                    <p>Ladder</p>
                </Link>
            </div>
        </div>
    )
}

export default TopPageItems