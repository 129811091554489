import React from 'react';
import './SearchPageFront.css';
import Navbar from './Navbar';
import Advtop from './Advtop';
import SearchPage from './SearchPage';
import Footer from './Footer';

function SearchPageFront() {

    return (
        <>
            <Navbar />
            <div className="wrapper">
                <SearchPage />
                <Advtop />
                <Footer />
            </div>
        </>
    )
}

export default SearchPageFront