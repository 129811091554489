import React from 'react';
import './Chatbar.css';


function Newsbar() {
    return (
        <div className="chatbar-container">
            <div className="spacing"></div>
            <div className="text-light-custom admin-panel-text">News</div>
            <div className="seperator"></div>
            <div className="inventory text-light-custom">
                <p>Coming Soon...</p>

            </div>
            <div className="spacing"></div>
        </div>
    )
}

export default Newsbar