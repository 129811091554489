import React from 'react';
import './Leftbar.css';

function Leftbar() {
    return (
        <div className="leftbar-container">
            <div className="text-light-custom tournament">Upcoming Competitive Matches</div>
            <div className="seperator"></div>
            <div className="inventory text-light-custom">
                <p>Coming Soon...</p>

            </div>
        </div>
    )
}

export default Leftbar