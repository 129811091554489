import React from 'react';
import axios from 'axios';

// Your function to update all accounts
const updateAllAccounts = async () => {
  try {
    // Make a request to your backend server to trigger the updateAllAccounts function
    const response = await axios.post('/api/updateAllAccounts');
    console.log(response.data);
  } catch (error) {
    console.error('Error updating accounts:', error);
  }
};

const AccountUpdater = () => {
  return (
    <div>
      <button onClick={updateAllAccounts}>Update Accounts</button>
      
    </div>
  );
};

export default AccountUpdater;
