import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const IndividualAccounts = ({ name }) => {
  const [accounts, setAccounts] = useState([]);

  useEffect(() => {

    // Access Firestore and fetch data
    const db = firebase.firestore();
    const fetchAccounts = async () => {
      try {
        const querySnapshot = await db.collection("accounts")
          .where("name", "==", name)
          .get();

        const accountData = querySnapshot.docs.map(doc => doc.data());
        setAccounts(accountData);
      } catch (error) {
        console.error('Error fetching accounts:', error);
      }
    };

    // Call the fetchAccounts function
    fetchAccounts();
  }, [name]);

  return (
    <div className="extra-accounts">
      {accounts.map((account) => (
        <div key={account.username} className="individual-account">
          <div className="extra-numerator"></div>
          <img className="summoner-icon" src={`https://ddragon.leagueoflegends.com/cdn/13.14.1/img/profileicon/${account.profileIconId}.png`} alt="Summoner Icon"></img>
          <div className="text-lighter">{account.username}</div>
        </div>
      ))}
    </div>
  );
};

export default IndividualAccounts;
