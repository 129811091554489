import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { Link, useLocation } from 'react-router-dom';

// Initialize Firebase
if (!firebase.apps.length) {
    firebase.initializeApp({
        apiKey: "AIzaSyAnX3pXtl3CYT6kr_uYK8dkxs7ZRYdc228",
        authDomain: "kjartan-app.firebaseapp.com",
        projectId: "kjartan-app",
        // ...
    });
}

const db = firebase.firestore();


const LadderList = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const page = Number(queryParams.get('page')) || 1;
    const itemsPerPage = 100;
    const start = (page - 1) * itemsPerPage;
    const end = page * itemsPerPage;

    const [topItems, setTopItems] = useState([]);


    // Map tier values to numeric values
    const tierMap = {
        "UNRANKED": 0,
        "IRON": 1,
        "BRONZE": 2,
        "SILVER": 3,
        "GOLD": 4,
        "PLATINUM": 5,
        "EMERALD": 6,
        "DIAMOND": 7,
        "MASTER": 8,
        "GRANDMASTER": 9,
        "CHALLENGER": 10,
    };

    const rankMap = {
        "IV": 0,
        "III": 1,
        "II": 2,
        "I": 3,
    }


    useEffect(() => {
        const fetchTopItems = async () => {
            try {
                const querySnapshot = await db
                    .collection('summoners')
                    .orderBy('tier', 'desc') // Order by tier in descending order (higher tier first)
                    .orderBy('rank', 'desc') // Then order by rank in descending order (higher rank first)
                    .orderBy('leaguePoints', 'desc') // Finally order by leaguePoints in descending order (higher leaguePoints first)
                    .limit(end)
                    .get();

                const items = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                // Sort the items using the tierMap, rankMap, and leaguePoints
                items.sort((a, b) => {
                    const tierA = tierMap[a.tier] || 0;
                    const tierB = tierMap[b.tier] || 0;

                    if (tierA !== tierB) {
                        return tierB - tierA; // Sort by tier in descending order
                    }

                    const rankA = rankMap[a.rank] || 0;
                    const rankB = rankMap[b.rank] || 0;

                    if (rankA !== rankB) {
                        return rankB - rankA; // Sort by rank in descending order
                    }

                    return b.leaguePoints - a.leaguePoints; // Sort by leaguePoints in descending order
                });

                setTopItems(items.slice(start, end));
            } catch (error) {
                console.error('Error fetching top items:', error);
            }
        };

        fetchTopItems();
    }, [start, end]);


    const renderPageLink = () => {
        if (page === 1) {
            return null; // Don't render the link for the first page
        }

        const prevPage = page - 1;
        return (
            <Link to={`?page=${prevPage}`} className="next-page-link">
                ◀
            </Link>
        );
    };

    const renderNextPageLink = () => {
        const nextPage = page + 1;
        return (
          <Link to={`?page=${nextPage}`} className="next-page-link right-arrow">
            ▶
          </Link>
        );
      };
      




    return (
        <>
            <div className="text-light-custom top-container" >
                <div className="top-text-container">
                    <span className="text-light pink">NA Ladder To </span>
                    <span className="text-light pink top-item-left">p {(page) * 100}</span>
                    <div className="ladder-top">
                        {renderPageLink()}
                        <Link to={`?page=${page + 1}`} className="next-page-link right-arrow">
                            ▶
                        </Link>
                    </div>
                </div>
            </div>

            <div className="seperator"></div>

            <div className="leaderboard">
                <div className="container">
                    {topItems.map((item) => (
                        <div className="player" key={item.id}>
                            <Link to={`/na/player/${item.name}`} className="name">
                                <i
                                    title={item.country}
                                    className={`flag-icon flag flag-icon-${item.flag}`}
                                ></i>
                                <span className="text-bold">{item.name}</span>
                            </Link>
                            <div className="role">
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/images/${item.role}.svg`}
                                    alt={item.role}
                                    className="hint--top position"
                                ></img>
                            </div>
                            <div className="rank rank-with-icon">
                                <img
                                    title={`${item.tier} ${item.rank}`}
                                    src={`${process.env.PUBLIC_URL}/assets/images/${item.tier}.png`}
                                    alt={item.tier}
                                    className="rank-icon"
                                ></img>
                                <span className="rank-lp">
                                    {item.leaguePoints}
                                    <span className="hide-xs"> LP</span>
                                </span>
                            </div>
                            <div className="team">
                                <Link to={`${process.env.PUBLIC_URL}/team/${item.team}`}>
                                    <div aria-label={item.team} className="hint--top">
                                        <img
                                            alt={item.team}
                                            src={`${process.env.PUBLIC_URL}/assets/images/teams/${item.teamlogo}.avif`}
                                        ></img>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default LadderList;