import './Advtop.css';

function Advtop() {
    return (
        <a href="https://discord.gg/rnNsJjwUAB" className="discord-container">
            <div>
                <div className="discord-banner">
                    <img title="Discord" src={`${process.env.PUBLIC_URL}/assets/images/discord_banner.png`} alt="discord" className="adv-top"></img>
                </div>
            </div>
        </a>
    )
}

export default Advtop