import React, { useState, useContext } from 'react';
import { AuthContext } from './App'; // Assuming the AuthContext is in the same file as App.js
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
  const { handleAuthentication } = useContext(AuthContext);
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setError('');

    // Perform authentication logic
    if (code === 'raechu') {
      handleAuthentication(code);
      // Redirect after successful authentication
      navigate('/Update');
    } else {
      setError('Invalid code. Please try again.');
    }
  };

  return (
    <div className="login-page">
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="password"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          placeholder="Enter your code"
        />
        <button type="submit">Login</button>
        {error && <div className="error-message">{error}</div>}
      </form>
    </div>
  );
};

export default LoginPage;
