import React from 'react';
import './TopTenLadder.css';
import TopItemsList from './TopItemsList';

function TopTenLadder() {

  return (

    <div className="TopTenLadder-container">
      <div className="text-light-custom top-container" >
        <div className="top-text-container">
          <span>NA TOP 
          <span className="default-text"> 10</span>
          </span>
        </div>
      </div>
      <div className="seperator"></div>
      <div className="leaderboard">
        <TopItemsList />
      </div>
    </div>

  )
}

export default TopTenLadder