import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

function Navbar() {

  const [searchValue, setSearchValue] = useState('');
  const [navbarTogglerActive, setNavbarTogglerActive] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Live');

  const handleSearch = () => {

    if (!searchValue) {
      // If the search query is empty, clear the search results
      setSearchValue([]);
      return;
    }

    if (selectedOption === 'Live') {
      // Navigate to the URL "/na/live/player={summonerNameURL}"
      window.location.href = `/na/live/player/${searchValue}`;
    } else if (selectedOption === 'Player') {
      // Navigate to the URL "/search/{summonerNameURL}"
      window.location.href = `/search/${searchValue}`;
    }
  };

  const handlePlayerSearch = () => {
    if (!searchValue) {
      return;
    }
    setSelectedOption('Player');
  };

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleDropdownOptionClick = (option) => {
    setSelectedOption(option);
    setDropdownOpen(false);
  };

  const handleSearchInputChange = (event) => {
    setSearchValue(event.target.value);
  };



  return (
    <div className="navbar-container-custom">
      <nav className="navbar navbar-expand-lg navbar-dark">

        <Link to="/" className="navbar-brand">
          <img className="img-logo-kjartan" src={`${process.env.PUBLIC_URL}/assets/images/fox_logo_mini.avif`} alt="logo" />
          <img className="brand-image" src={`${process.env.PUBLIC_URL}/assets/images/lolfox.png`} alt="lolfox" />
        </Link>

        

        <div className="search-container-nav-inner">
          <div className="dropdown-button">
            <button
              className="dropdown-button-toggle"
              onClick={handleDropdownToggle}
            >
              <p>{selectedOption}</p><div className="down-pointer" >▼</div>
            </button>
            {dropdownOpen && (
              <div className="dropdown-options">
                <div
                  className={`dropdown-option ${selectedOption === 'Live' ? 'active-option' : ''}`}
                  onClick={() => handleDropdownOptionClick('Live')}
                >
                  <p>Live</p>
                </div>
                <div
                  className={`dropdown-option ${selectedOption === 'Player' ? 'active-option' : ''}`}
                  onClick={() => handleDropdownOptionClick('Player')}
                >
                  <p>Player</p>
                </div>
              </div>
            )}
          </div>

          <input
            type="text"
            placeholder={`${selectedOption} Search...`}
            value={searchValue}
            onChange={handleSearchInputChange}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearch();
              }
            }}
            className="search-input-nav"
          />

          <button onClick={handleSearch} className="search-button-nav">
            <img className="search-img" src="/assets/images/Search.svg" alt="Search"></img>
          </button>

          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        </div>

        <div className={`collapse navbar-collapse ${navbarTogglerActive ? '' : 'hidden'}`} id="navbarSupportedContent">

          <div className={` right-item-nav search-container-nav ${navbarTogglerActive ? 'show' : ''}`}>

          </div>

          <ul className="navbar-nav mr-auto right-item-nav">
            <li className="nav-item">
              <div className="pink-nav-line"></div>
              <div className="pink-nav"></div>
              <Link className="full-link" to="/search">Contact</Link>
            </li>
            <li className="nav-item">
              <div className="pink-nav-line-last"></div>
              <div className="pink-nav"></div>
              <Link className="full-link" to="/leagues">Leagues</Link>
            </li>
          </ul>
        </div>

      </nav>
    </div>
  )
}

export default Navbar