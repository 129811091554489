import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import { Link } from 'react-router-dom';
import axios from 'axios';

if (!firebase.apps.length) {
    firebase.initializeApp({
        apiKey: "AIzaSyAnX3pXtl3CYT6kr_uYK8dkxs7ZRYdc228",
        authDomain: "kjartan-app.firebaseapp.com",
        projectId: "kjartan-app",
        // ...
    });
}

const db = firebase.firestore(); // Get a reference to the Firestore database

const ProLookup = ({ summonerName }) => {

    // Set the base URL for Axios
    axios.defaults.baseURL = 'http://lolfox.net:5000';

    const [playerData, setPlayerData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!summonerName) {
            setPlayerData(null);
            setLoading(false);
            setError('Summoner name is empty.');
            return;
        }

        setLoading(true);
        setError(null);

        // Make the Axios request to fetch player data from the backend server
        axios
            .get(`/api/pro/${summonerName}`)
            .then((response) => {
                const data = response.data;
                if (data.error) {
                    setError(data.error);
                    setPlayerData(null);
                } else {
                    setPlayerData(data.playerData);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching player data:', error);
                setError('Error fetching player data.');
                setPlayerData(null);
                setLoading(false);
            });
    }, [summonerName]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        //console.log({ summonerName })
        return <>
            <p className="text-light">{summonerName}</p>
            <p className="text-small text-lighter-scoreboard">Not 1Registered</p>
        </>
    }

    if (!playerData) {
        console.log({ summonerName })
        return <>
            <p className="text-light">{summonerName}</p>
            <p className="text-small text-lighter-scoreboard">Not 2Registered</p>
        </>
    }
    if (playerData) {
        return <>
        <div className="pro-card name">
            <Link to={`/na/player/${playerData.name}`} className="scoreboard-name-pro">
                <div aria-label={playerData.role} className="hint--top scoreboard-role">
                    <img alt={playerData.role} src={`${process.env.PUBLIC_URL}/assets/images/${playerData.role}.svg`}></img>
                </div>

                <i title={playerData.flag} className={`flag-icon flag flag-scoreboard flag-icon-${playerData.flag}`}></i>

                <div>
                    <p className="text-light">{playerData.name}</p>
                    <p className="text-small  text-lighter-scoreboard">{summonerName}</p>
                </div>
            </Link>

            <Link style={{ borderRadius: '8px' }} to={`/team/${playerData.team}`}>
                <div aria-label={playerData.team} className="hint--top">
                    <img alt={playerData.team} src={`${process.env.PUBLIC_URL}/assets/images/teams/${playerData.teamlogo}.png`}></img>
                </div>
            </Link>

        </div>
    </>
    }

    return <>
        <div className="pro-card name">
            <Link to={`/na/player/${playerData.name}`} className="scoreboard-name-pro">
                <div aria-label={playerData.role} className="hint--top scoreboard-role">
                    <img alt={playerData.role} src={`${process.env.PUBLIC_URL}/assets/images/${playerData.role}.svg`}></img>
                </div>

                <i title={playerData.flag} className={`flag-icon flag flag-scoreboard flag-icon-${playerData.flag}`}></i>

                <div>
                    <p className="text-light">{playerData.name}</p>
                    <p className="text-small  text-lighter-scoreboard">{summonerName}</p>
                </div>
            </Link>

            <Link style={{ borderRadius: '8px' }} to={`/team/${playerData.team}`}>
                <div aria-label={playerData.team} className="hint--top">
                    <img alt={playerData.team} src={`${process.env.PUBLIC_URL}/assets/images/teams/${playerData.teamlogo}.png`}></img>
                </div>
            </Link>

        </div>
    </>
};

export default ProLookup;