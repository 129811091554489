import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { Link } from 'react-router-dom';

// Initialize Firebase
firebase.initializeApp({
  apiKey: "AIzaSyAnX3pXtl3CYT6kr_uYK8dkxs7ZRYdc228",
  authDomain: "kjartan-app.firebaseapp.com",
  projectId: "kjartan-app",
  // ...
});



const TopItemsList = () => {

  const [topItems, setTopItems] = useState([]);
  const [loading, setLoading] = useState(true);

  const db = firebase.firestore();

  // Map tier values to numeric values
  const tierMap = {
    "UNRANKED": 0,
    "IRON": 1,
    "BRONZE": 2,
    "SILVER": 3,
    "GOLD": 4,
    "PLATINUM": 5,
    "EMERALD": 6,
    "DIAMOND": 7,
    "MASTER": 8,
    "GRANDMASTER": 9,
    "CHALLENGER": 10,
  };

  const rankMap = {
    "IV": 0,
    "III": 1,
    "II": 2,
    "I": 3,
  }

  useEffect(() => {
    const fetchTopItems = async () => {
      try {
        setLoading(true);
        const querySnapshot = await db.collection("summoners").get();
        const items = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        // Add the new feature item.tier_lowercase
        items.forEach(item => {
          item.tier_lowercase = item.tier.toLowerCase() ?? "unranked";
        });

        // Sort the accounts based on tier, rank, and leaguePoints
        items.sort((a, b) => {
          const tierComparison = tierMap[b.tier] - tierMap[a.tier];
          if (tierComparison !== 0) return tierComparison;

          const rankComparison = rankMap[b.rank] - rankMap[a.rank];
          if (rankComparison !== 0) return rankComparison;

          return b.leaguePoints - a.leaguePoints;
        });

        // Get the top 10 players
        const top10Players = items.slice(0, 10);
        setTopItems(top10Players);
        setLoading(false);

      } catch (error) {
        console.error("Error fetching top items: ", error);
      }
    };

    fetchTopItems();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className='container'>

      {topItems.map(item => (
        <div className="player" key={item.name}>
          <Link to={`/na/player/${item.name}`} className="name">
            <i title={item.country} className={`flag-icon flag flag-icon-${item.flag}`}></i>
            <span className="text-bold">{item.name}</span>
          </Link>
          <div className="role">
            <img src={`${process.env.PUBLIC_URL}/assets/images/${item.role}.svg`} alt={item.role} className="hint--top position"></img>
          </div>
          <div className="rank rank-with-icon">
            <img title={`${item.tier} ${item.rank}`} src={`${process.env.PUBLIC_URL}/assets/images/${item.tier}.png`} alt={item.rank} className="rank-icon"></img>
            <span className="rank-lp">
              {item.leaguePoints}
              <span className="hide-xs"> LP</span>
            </span>
          </div>
          <div className="team">
            <Link to={`${process.env.PUBLIC_URL}/team/${item.team}`}>
              <div aria-label={item.team} className="hint--top">
                <img className="team-img" alt={item.team} src={`${process.env.PUBLIC_URL}/assets/images/teams/${item.teamlogo}.avif`}></img>
              </div>
            </Link>
          </div>
        </div>
      ))}

    </div>
  );
};

export default TopItemsList;