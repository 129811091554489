// LivePlayerInfo.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';

const LivePlayerInfo = ({ summonerName }) => {

    // Set the base URL for Axios
    axios.defaults.baseURL = 'http://lolfox.net:5000';

    const [rankedInfo, setRankedInfo] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // Function to calculate win rate
    const calculateWinRate = (wins, losses) => {
        const wr = wins && losses ? ((wins / (wins + losses)) * 100).toFixed(0) : 'N/A';
        let wr_color = "#7B7A8E";
        if (wr > 49) {
            wr_color = "#00BBA3";
        }
        if (wr > 59) {
            wr_color = "#0093FF";
        }
        if (wr > 69) {
            wr_color = "#FF8200";
        }
        return { wr, wr_color };
    };

    useEffect(() => {
        if (!summonerName) {
            // If summonerName is empty, clear the ranked info
            setRankedInfo(null);
            return;
        }

        setLoading(true);
        setError(null);

        // Make the API call to your backend server
        axios
            .get(`/api/summoner/ranked-info?summonerName=${summonerName}`)
            .then((response) => {
                const { wins, losses } = response.data;
                const totalGames = wins + losses; // Calculate the total number of games
                const winRateData = calculateWinRate(wins, losses);
                setRankedInfo({
                    ...response.data,
                    wr: winRateData.wr,
                    wr_color: winRateData.wr_color,
                    games: totalGames, // Add the "games" field to the state
                });
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching ranked info:', error);
                setError('Error fetching ranked info');
                setLoading(false);
            });
    }, [summonerName]);


    return (
        <>
            {loading && <td className="tier-rank text-lighter-scoreboard">Loading...</td>}
            {!loading && error && (
                <>
                    <td>
                        <img
                            src={`https://trackercdn.com/cdn/tracker.gg/lol/ranks/2023/icons/unranked.svg`}
                            alt="Unranked"
                            className="tier-image"
                        ></img>
                    </td>
                    <td className="text-lighter-scoreboard">
                        <p>Unranked</p>
                    </td>
                    <td className="tier-rank text-lighter-scoreboard">
                        <strong style={{ color: "#7B7A8E" }} className="winrate">
                            N/A
                        </strong>
                        <div className="winrate-visual">
                                <div className="winrate-bar">
                                <div style={{ backgroundColor: "#7B7A8E" }} className="wins"></div>
                                </div>
                            </div>
                    </td>
                </>
            )}

            {!loading && !error && rankedInfo && (
                <>
                    <td>
                        <img
                            src={`https://trackercdn.com/cdn/tracker.gg/lol/ranks/2023/icons/${rankedInfo.tier_lowercase}.svg`}
                            alt={rankedInfo.tier}
                            className="tier-image"
                        ></img>
                    </td>
                    <td className="text-lighter-scoreboard">
                        <p>{rankedInfo.tier_proper} {rankedInfo.rank} ({rankedInfo.leaguePoints} LP)</p>
                    </td>
                    <td className="tier-rank text-lighter-scoreboard">
                        <div className="winrate-innner">
                            <strong style={{ color: rankedInfo.wr_color }} className="winrate">
                                {rankedInfo.wr}% ({rankedInfo.games} Games)
                            </strong>
                            <div className="winrate-visual">
                                <div className="winrate-bar">
                                <div style={{ backgroundColor: rankedInfo.wr_color, width: `${rankedInfo.wr}%` }} className="wins"></div>
                                </div>
                            </div>
                        </div>
                    </td>
                </>
            )}
        </>
    );
};

export default LivePlayerInfo;
