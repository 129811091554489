import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import Leftbar from './components/Leftbar';
import Advtop from './components/Advtop';
import TopTenLadder from './components/TopTenLadder';
import Newsbar from './components/Newsbar';
import UserPage from './components/UserPage';
import LadderPage from './components/LadderPage';
import './App.css';
import SearchPageFront from './components/SearchPageFront';
import LeagueAccountInfo from './components/LeagueAccountInfo';
import LoginPage from './LoginPage'; // Import the LoginPage component
import LiveGameSearch from './components/LiveGameSearch';
import Footer from './components/Footer';
import TextFileDisplay from './components/TextFileDisplay';
import TopPageItems from './components/TopPageItems';

// Create AuthContext
export const AuthContext = React.createContext();

function App() {
  // State to track authentication status
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Function to handle authentication
  const handleAuthentication = (code) => {
    // Check if the code is correct (in this case, "secretCode")
    if (code === 'raechu') {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, handleAuthentication }}>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="//riot.txt" element={<TextFileDisplay />} />
          <Route path="/NA/Player/:name" element={<UserPage />} />
          <Route path="/Ladder" element={<LadderPage />} />
          <Route path="/Search" element={<SearchPageFront />} />
          <Route path="/Search/:name" element={<SearchPageFront />} />
          <Route path="/na/live/player/:name" element={<LiveGameSearch />} />
          {/* Secure route using conditionally rendering */}
          <Route
            path="/Update"
            element={isAuthenticated ? <LeagueAccountInfo /> : <Navigate to="/login" />}
          />
          <Route path="/login" element={<LoginPage />} />
        </Routes>
      </Router>
    </AuthContext.Provider>
  );
}

const Home = () => {
  return (
    <>
      <Navbar />
      <div className="wrapper">
        <TopPageItems />
        <div className="wrapper-ladder-10">
          <TopTenLadder />
          <div className="right-wrapper">
            <Leftbar />
            <Newsbar />
          </div>
        </div>
        <Advtop />
        <Footer />
      </div>
    </>
  );
};

export default App;







/*import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Leftbar from './components/Leftbar';
import Advtop from './components/Advtop';
import TopTenLadder from './components/TopTenLadder';
import Newsbar from './components/Newsbar';
import UserPage from './components/UserPage';
import LadderPage from './components/LadderPage';
import './App.css';
import SearchPageFront from './components/SearchPageFront';
import LiveSearchPageFront from './components/LiveSearchPageFront';
import LeagueAccountInfo from './components/LeagueAccountInfo';
// Create AuthContext
export const AuthContext = React.createContext();

function App() {

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/NA/Player/:name" element={<UserPage />} />
          <Route path="/Ladder" element={<LadderPage />} />
          <Route path="/Search" element={<SearchPageFront />} />
          <Route path="/Search/Live" element={<LiveSearchPageFront />} />
          <Route path="/Update" element={<LeagueAccountInfo />} />
        </Routes>
      </Router>
    </>
  );
}

const Home = () => {
  return (
    <>
      <Navbar />
      <div className="wrapper">
        <Advtop />
        <div className="wrapper-ladder-10">
          <TopTenLadder />
          <div className="right-wrapper">
            <Leftbar />
            <Newsbar />
          </div>
        </div>
      </div>
    </>
  );
};

export default App;
*/