import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import './UserPage.css';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';
import AccountInfo from './AccountInfo';
import Footer from './Footer';

// Initialize Firebase
firebase.initializeApp({
  apiKey: "AIzaSyAnX3pXtl3CYT6kr_uYK8dkxs7ZRYdc228",
  authDomain: "kjartan-app.firebaseapp.com",
  projectId: "kjartan-app",
  // ...
});

const db = firebase.firestore();

const UserPage = () => {
  const { name } = useParams();
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const querySnapshot = await db.collection('summoners').where('name', '==', name).get();
        const data = querySnapshot.docs.map(doc => doc.data());
        setUserData(data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [name]);

  return (
    <>
      <Navbar />
      <div className="wrapper">

        <div>
          {userData ? (
            <div ClassName="pfp-container">
              {userData.map(user => (
                <>
                  <div key={user.id} className="player-profile-wrapper">
                    <div className="upper-pfp">
                      <img className="summoner-icon-big" alt="Summoner Icon" src={`https://ddragon.leagueoflegends.com/cdn/13.14.1/img/profileicon/${user.profileIconId}.png`}></img>
                      <h1 className="text-light profile-name">{user.name}</h1><br />
                    </div>
                    <div className="seperator"></div>
                    <div className="profile-contents">
                      <div className="profile-info">
                        <div className="name card-item">
                          <div className="pink-line"></div>
                          <i
                            title={user.flag}
                            className={`flag-icon flag-icon-pfp flag flag-icon-${user.flag}`}
                          ></i>
                          <span className="text-light">{user.country}</span>
                        </div>
                        <div className="name card-item">
                          <div className="pink-line"></div>
                          <img src={`${process.env.PUBLIC_URL}/assets/images/${user.role}.svg`} alt={user.role} className="hint--top position"></img>
                          <span className="text-light role-pos">{user.role}</span>
                        </div>
                        <div className="name card-item">
                          <div className="pink-line"></div>
                          <img src={`${process.env.PUBLIC_URL}/assets/images/${user.league}.svg`} alt={user.league} className="hint--top position white-svg"></img>
                          <span className="text-light role-pos">{user.league}</span>
                        </div>
                        <div className="name card-item">
                          <div className="pink-line"></div>
                          <Link to={`${process.env.PUBLIC_URL}/team/${user.teamlogo}`}>
                            <div aria-label={user.team} className="hint--top">
                              <img alt={user.team} src={`${process.env.PUBLIC_URL}/assets/images/teams/${user.teamlogo}.png`}></img>
                            </div>
                          </Link>
                          <span className="text-light role-pos">{user.team}</span>
                        </div>
                      </div>
                      <div className="profile-info profile-right">
                        <div className="name card-item">
                          {user.twitch && ( // Added condition to render only when item.role is not empty
                            <div className="socials-item">
                              <a href={user.twitch} target="_blank" rel="noopener noreferrer">
                                <img
                                  src={`${process.env.PUBLIC_URL}/assets/images/twitch_small.png`}
                                  alt="Twitch"
                                  className="hint--top socials-user"
                                ></img>
                              </a>
                            </div>
                          )}
                          {user.youtube && ( // Added condition to render only when item.role is not empty
                            <div className="socials-item">
                              <a href={user.youtube} target="_blank" rel="noopener noreferrer">
                                <img
                                  src={`${process.env.PUBLIC_URL}/assets/images/youtube_small.png`}
                                  alt="Youtube"
                                  className="hint--top socials-user"
                                ></img>
                              </a>
                            </div>
                          )}
                          {user.twitter && ( // Added condition to render only when item.role is not empty
                            <div className="socials-item">
                              <a href={user.twitter} target="_blank" rel="noopener noreferrer">
                                <img
                                  src={`${process.env.PUBLIC_URL}/assets/images/twitter_small.png`}
                                  alt="Twitter"
                                  className="hint--top socials-user"
                                ></img>
                              </a>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accounts-wrapper">
                    <AccountInfo name={user.name} />
                  </div>
                </>
              ))}
            </div>
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </div>
      <Footer />
    </>

  );
};

export default UserPage;
