import React, { useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';


const AddDataForm = () => {
  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');
  const [country, setCountry] = useState('');
  const [flag, setFlag] = useState('');
  const [team, setTeam] = useState('');
  const [teamlogo, setTeamlogo] = useState('');
  const [league, setLeague] = useState('');
  const [youtube, setYoutube] = useState('');
  const [twitch, setTwitch] = useState('');
  const [twitter, setTwitter] = useState('');

  const addData = async (e) => {
    e.preventDefault(); // Prevent form submission and page refresh

    const newData = {
      name,
      username,
      role,
      country,
      flag,
      team,
      teamlogo,
      league,
      youtube,
      twitch,
      twitter,
    };

    try {
      const response = await fetch('http://lolfox.net:5000/api/addData', { // Change the URL to include port 5000
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newData),
      });

      if (response.ok) {
        console.log('Data updated successfully');

        // Reset the input values
        setName('');
        setUsername('');
        setRole('');
        setCountry('');
        setFlag('');
        setTeam('');
        setTeamlogo('');
        setLeague('');
        setTwitch('');
        setYoutube('');
        setTwitter('');
      } else {
        console.error('Failed to add data:', response.status);
      }
    } catch (error) {
      console.error('Error adding data:', error);
    }
  };

  return (
    <div style={{ textAlign: 'right', display: 'flex' }}>
      <form onSubmit={addData}>
        <label>
          Name:
          <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
        </label>
        <br />
        <label>
          Username:
          <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
        </label>
        <br />
        <label>
          Role:
          <input type="text" value={role} onChange={(e) => setRole(e.target.value)} />
        </label>
        <br />
        <label>
          Country:
          <input type="text" value={country} onChange={(e) => setCountry(e.target.value)} />
        </label>
        <br />
        <label>
          Flag:
          <input type="text" value={flag} onChange={(e) => setFlag(e.target.value)} />
        </label>
        <br />
        <label>
          Team:
          <input type="text" value={team} onChange={(e) => setTeam(e.target.value)} />
        </label>
        <br />
        <label>
          Team Logo:
          <input type="text" value={teamlogo} onChange={(e) => setTeamlogo(e.target.value)} />
        </label>
        <br />
        <label>
          Comp League:
          <input type="text" value={league} onChange={(e) => setLeague(e.target.value)} />
        </label>
        <br />
        <label>
          Youtube:
          <input type="text" value={youtube} onChange={(e) => setYoutube(e.target.value)} />
        </label>
        <br />
        <label>
          Twitch:
          <input type="text" value={twitch} onChange={(e) => setTwitch(e.target.value)} />
        </label>
        <br />
        <label>
          Twitter:
          <input type="text" value={twitter} onChange={(e) => setTwitter(e.target.value)} />
        </label>
        <br />
        <button type="submit">Add Data</button>
      </form>
    </div>
  );
};

export default AddDataForm;