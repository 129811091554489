// CookiesNotice.js

import React, { useState, useEffect } from 'react';

const CookiesNotice = () => {
  const [showNotice, setShowNotice] = useState(true);

  const handleAcceptCookies = () => {
    setShowNotice(false);
    // You can save the user's acceptance in localStorage or a cookie to remember their choice.
    // For example: localStorage.setItem('cookiesAccepted', 'true');
  };

  useEffect(() => {
    // Function to check if the user is in Europe based on their language settings
    const isUserInEurope = () => {
      // An array of language codes for European countries
      const europeanLanguages = ['en-GB', 'en-IE', 'de', 'fr', 'es', 'it', 'nl', 'pt', 'sv', 'da', 'fi', 'el', 'cs', 'pl', 'hu', 'et', 'lv', 'lt', 'mt', 'sk', 'sl', 'hr', 'ro', 'bg'];

      // Check if any of the user's language settings match the European languages
      const userLanguages = navigator.languages || [navigator.language];
      const isInEurope = userLanguages.some(language => europeanLanguages.includes(language));

      return isInEurope;
    };

    // Check if the user is in Europe and has not accepted cookies
    const cookiesAccepted = localStorage.getItem('cookiesAccepted') === 'true';
    if (!cookiesAccepted && isUserInEurope()) {
      setShowNotice(true);
    } else {
      setShowNotice(false);
    }
  }, []);

  return (
    <>
      {showNotice && (
        <>
          <div className="cookies-notice">
            <p>
              This website uses cookies to ensure you get the best experience on our website.
              By continuing to use this site, you consent to the use of cookies.
            </p>
          </div>
          <div className="cookies-button">
            <button onClick={handleAcceptCookies}>Got it!</button>
          </div>
        </>
      )}
    </>
  );
};

export default CookiesNotice;
