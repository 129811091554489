import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { Link, useLocation } from 'react-router-dom';
import './SearchPage.css';
import IndividualAccounts from './IndividualAccounts';

// Initialize Firebase
firebase.initializeApp({
    apiKey: "AIzaSyAnX3pXtl3CYT6kr_uYK8dkxs7ZRYdc228",
    authDomain: "kjartan-app.firebaseapp.com",
    projectId: "kjartan-app",
    // ...
});

const db = firebase.firestore();

const SearchPage = () => {
  const [query, setQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const location = useLocation();

  const handleSearch = async (searchQuery) => {
    if (!searchQuery) {
      // If the search query is empty, clear the search results
      setSearchResults([]);
      return;
    }

    try {
      const lowercaseQuery = searchQuery.toLowerCase();

      const querySnapshot = await db.collection('summoners')
        .where('nameId', '>=', lowercaseQuery)
        .where('nameId', '<=', lowercaseQuery + '\uf8ff')
        .get();

      const results = querySnapshot.docs.map((doc) => doc.data());

      const usernameQuerySnapshot = await db.collection('summoners')
        .where('usernameId', '>=', lowercaseQuery)
        .where('usernameId', '<=', lowercaseQuery + '\uf8ff')
        .get();

      const usernameResults = usernameQuerySnapshot.docs.map((doc) => doc.data());

      const combinedResults = [...results, ...usernameResults];

      // Filter out duplicate entries based on nameid
      const uniqueResults = Array.from(
        new Map(combinedResults.map((result) => [result.nameid, result])).values()
      );

      setSearchResults(uniqueResults);
    } catch (error) {
      console.error('Error searching database:', error);
    }
  };

  // Call handleSearch on page load with the name from the URL
  useEffect(() => {
    const path = location.pathname;
    const pathParts = path.split('/').filter(part => part.trim() !== '');
    if (pathParts.length === 2 && pathParts[0].toLowerCase() === 'search') {
      setQuery(pathParts[1].replace('%20', ' '));
      handleSearch(pathParts[1].replace('%20', ' '));
    }
  }, [location]);

  return (
        <>
            <div className="centered-container">
                <div className="Search-container">

                    <div className="search-container">
                        <input
                            type="text"
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    handleSearch();
                                }
                            }}
                            placeholder="Search..."
                            className="search-input"
                        />
                        <button onClick={handleSearch} className="search-button">
                            <img className="search-img" src="/assets/images/Search.svg" alt="Search"></img>
                        </button>
                    </div>
                    <div className="options-container">
                        <div className={`options-buttons active-pink `}>
                            <Link className="option-button" to="/search">Player Search</Link>
                        </div>
                    </div>

                </div>
            </div>
            <div className="centered-container">
                <div className="lookup-wrapper">
                    {searchResults.map((result) => (
                        <div className="lookup-container">
                            <div className="lookup-player" key={result.nameid}>
                                <Link to={`/na/player/${result.name}`} className="name">
                                    <i
                                        title={result.flag}
                                        className={`flag-icon flag flag-icon-${result.flag}`}
                                    ></i>
                                    <span className="text-light">{result.name}</span>
                                </Link>
                                {result.twitch && ( // Added condition to render only when item.role is not empty
                                    <div className="youtube-lookup">
                                        <a href={result.twitch} target="_blank" rel="noopener noreferrer">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/assets/images/twitch_small.png`}
                                                alt="Twitch"
                                                className="hint--top socials-user"
                                            ></img>
                                        </a>
                                    </div>
                                )}
                                {result.youtube && ( // Added condition to render only when item.role is not empty
                                    <div className="twitch-lookup">
                                        <a href={result.youtube} target="_blank" rel="noopener noreferrer">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/assets/images/youtube_small.png`}
                                                alt="Youtube"
                                                className="hint--top socials-user"
                                            ></img>
                                        </a>
                                    </div>
                                )}
                                {result.twitter && ( // Added condition to render only when item.role is not empty
                                    <div className="youtube-lookup">
                                        <a href={result.twitter} target="_blank" rel="noopener noreferrer">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/assets/images/twitter_small.png`}
                                                alt="Twitter"
                                                className="hint--top socials-user"
                                            ></img>
                                        </a>
                                    </div>
                                )}
                                <div className="role">
                                    <img
                                        src={`${process.env.PUBLIC_URL}/assets/images/${result.role}.svg`}
                                        alt={result.role}
                                        className="hint--top position"
                                    ></img>
                                </div>
                            </div>
                            <div className="seperator"></div>


                            <IndividualAccounts name={result.name} />

                        </div>

                    ))}
                </div>
            </div>
        </>
    );
};

export default SearchPage;