import React, { useState } from 'react';
import axios from 'axios';
import firebase from 'firebase/compat/app';
import Chatbar from './Chatbar';
import LeagueAccountAddition from './LeagueAccountAddition';
import AccountUpdater from './AccountUpdater';
import Navbar from './Navbar';
import Footer from './Footer';

if (!firebase.apps.length) {
    firebase.initializeApp({
        apiKey: "AIzaSyAnX3pXtl3CYT6kr_uYK8dkxs7ZRYdc228",
        authDomain: "kjartan-app.firebaseapp.com",
        projectId: "kjartan-app",
        // ...
    });
}

const LeagueAccountInfo = () => {
    const [userName, setUserName] = useState('');
    const [summonerName, setSummonerName] = useState('');
    const [accountInfo, setAccountInfo] = useState(null);

    axios.defaults.baseURL = 'http://lolfox.net:5000';

    const fetchSummonerData = async () => {
        try {
            const response = await axios.get(`/api/summoner?summonerName=${summonerName}`);
            setAccountInfo(response.data);
            console.log("accountInfo:", response.data);
            return response.data; // Return the response data from the function
        } catch (error) {
            console.error('Error fetching account data:', error);
            setAccountInfo(null);
            return null;
        }
    };

    const updateUserData = async (summonerData) => {
        try {
            await axios.post('/api/updateUserData', {
                summonerName,
                userNameIn: userName,
                accountInfo,
            });
        } catch (error) {
            console.error('Error updating user data:', error);
        }
    };

    const handleSearch = async () => {
        const summonerData = await fetchSummonerData(); // Get the summoner data from fetchSummonerData
        if (summonerData) {
            await updateUserData(summonerData); // Pass the summoner data to updateUserData
        }
    };

    return (
        <div style={{ margin: '100px' }}>
            <Navbar />
            <div style={{ marginBottom: '10px' }}>
                <Chatbar />
            </div>
            <div className="chatbar-container">
                <input
                    type="text"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    placeholder="Enter Player Name"
                />
                <input
                    type="text"
                    value={summonerName}
                    onChange={(e) => setSummonerName(e.target.value)}
                    placeholder="Enter Summoner Name"
                />
                <button onClick={handleSearch}>Update</button>

                {accountInfo && (
                    <div className="text-light">
                        <h2>Ranked Data:</h2>
                        <div>
                            <strong>Tier:</strong> {accountInfo.rankedData[0]?.tier}<br />
                            <strong>Rank:</strong> {accountInfo.rankedData[0]?.rank}<br />
                            <strong>League Points:</strong> {accountInfo.rankedData[0]?.leaguePoints}<br />
                            <strong>Wins:</strong> {accountInfo.rankedData[0]?.wins}<br />
                            <strong>Losses:</strong> {accountInfo.rankedData[0]?.losses}<br />
                            <strong>profileIconId:</strong> {accountInfo.summonerData.profileIconId}<br />
                            <strong>accountId:</strong> {accountInfo.summonerData.accountId}<br />
                        </div>
                    </div>
                )}
            </div>
            <AccountUpdater />
            <Footer />
        </div>
    );
};




export default LeagueAccountInfo;